import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesLightConesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Honkai: Star Rail Light Cones</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_cones_guide.png"
            alt="Light Cones"
          />
        </div>
        <div className="page-details">
          <h1>Light Cones</h1>
          <h2>
            The guide explains the Light Cone system in Honkai: Star Rail.
          </h2>
          <p>
            Last updated: <strong>09/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Light Cones" />
        <StaticImage
          src="../../../images/starrail/generic/guide_cone.jpg"
          alt="Characters"
        />
        <p>
          Light Cones are a type of equipment that all Characters can equip.
          They are the{' '}
          <strong>
            equivalent of weapons in other games and provide stat bonuses to the
            equipped Character
          </strong>
          .
        </p>
        <p>
          Similar to Characters, Light Cones also have a Level ranging from 1 to
          80. They can be leveled up and ascended as well. Doing so will
          increase the stat bonus that they provide to the equipped Character.
        </p>
        <p>Each Light Cone has its own rarity - either 3★, 4★, or 5★.</p>
        <p>
          Each Light Cone also has a{' '}
          <strong>
            Light Cone Ability which is a passive bonus that is tied to one of
            the seven Paths
          </strong>
          . A Light Cone Ability starts at Rank 1 and can be upgraded to Rank 5
          by using the Superimpose function.
        </p>
        <p>
          To use the Superimpose function, you will need to expend duplicate
          Light Cones of the one that you are upgrading. Each duplicate Light
          Cone that you use will increase the Light Cone Ability’s Rank by 1.
          Note that any Light Cones used as a Superimpose material will be
          consumed and destroyed. Increasing the Rank of a Light Cone Ability
          will enhance some or all of the numerical portion of the bonus that it
          provides.
        </p>
        <p>
          All Light Cones can be equipped to any Character, however{' '}
          <strong>
            only Characters of the Light Cone’s corresponding Path will be able
            to use its Light Cone Ability
          </strong>
          . This means that a Character that is equipped with a Light Cone of a
          different Path will only gain the stat bonuses that the Light Cone
          provides and not its Light Cone Ability.
        </p>
        <SectionHeader title="Example Light Cone" />
        <p>Here's an example Light Cone:</p>
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="in-the-name-of-the-world" />
          </Col>
        </Row>
        <br></br>
        <p>For the full list of available Light Cones go here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Light Cones"
            link="/star-rail/light-cones"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_lightcone.png"
                alt="Light Cones"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesLightConesPage;

export const Head: React.FC = () => (
  <Seo
    title="Light Cones guide | Honkai: Star Rail | Prydwen Institute"
    description="The guide explains the Light Cone system in Honkai: Star Rail."
  />
);
